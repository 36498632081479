import React from 'react'
import "./Checkout.css"
import Header from '../Header/Header';
import { useStateValue } from '../../StateProvider';
import CheckoutProduct from '../CheckoutProduct/CheckoutProduct';
import ProductItem from '../ProductItem/ProductItem'

const Checkout = () => {

  const [{ basket, user }, dispatch] = useStateValue();
  return (
    <>
    <Header/>
    <div className="checkout">
      <div className="checkout__left">
        <img
          className="checkout__ad"
          src="https://images-na.ssl-images-amazon.com/images/G/02/UK_CCMP/TM/OCC_Amazon1._CB423492668_.jpg"
          alt=""
        />

        {basket?.length === 0 ? (
          <div>
            <h2>Your Shopping Basket is Empty</h2>
            <p className="">
              You have no items in your basket.  To Buy one or more items, click "Add to Basket" next to item
            </p>
          </div>
        ) : (

        <div>
          <h3>Hello, {user?.email}</h3>
          
          <h2 className="checkout__title">Your shopping Basket</h2>
          
          {basket.map(item => (
             <ProductItem
             key={item.id}
             id={item.id}
             title={item.title}
             image={item.image}
             price={item.price}
             rating={item.rating}
           />
           
          ))}

        </div>
         )}
      </div>
      <div className="checkout__right">
      
      </div>
    </div>
    </>
  )
}

export default Checkout