import React from 'react'
import "./Home.css"
import Header from '../Header/Header';
import Product from '../Product/Product';

const Home = () => {
  return (
    <>
      <Header></Header>
      <div className='home'>

        <img className='home__image'
          src="amazonbanner.jpg"
          alt="Amazon Banner" />
        <div className="home__row">
          <Product
            id={"122456456"}
            title={"Amazon Halo View fitness tracker, with color display for at-a-glance access to heart rate, activity, and sleep tracking – Active Black – Medium/Large "}
            price={44.99}
            rating={3}
            image={"watch1.jpg"}
          />
           <Product
           id={"43456544"}
            title={"Echo Dot (3rd Gen, 2018 release) - Smart speaker with Alexa - Charcoal "}
            price={17.99}
            rating={4}
            image={"echo1.jpg"}
          />
          </div>
          <div className="home__row">
           <Product
           id={"7634234"}
            title={"Fire TV Stick 4K, brilliant 4K streaming quality, TV and smart home controls, free and live TV "}
            price={44.99}
            rating={4}
            image={"firestick1.jpg"}
          />
           <Product
           id={"03453454"}
            title={"Fire HD 8 tablet, 8\" HD display, 32 GB, (2020 release), designed for portable entertainment, Black"}
            price={44.99}
            rating={5}
            image={"firehd1.jpg"}
          />
          <Product
          id={"345656456"}
            title={"Echo Show 8 (1st Gen, 2019 release) -- HD smart display with Alexa – Unlimited Cloud Photo Storage – Digital Photo Display - Charcoal "}
            price={54.99}
            rating={2}
            image={"echoshow1.jpg"}
          />
          </div>

          <div className="home__row">
          <Product
          id={"98764764323"}
            title={"Luna Controller – The best wireless controller for Luna, Amazon’s new cloud gaming service "}
            price={39.99}
            rating={3}
            image={"luna1.jpg"}
          />
          <Product
          id={"60590778"}
            title={"Fire TV Cube, Hands-free streaming device with Alexa, 4K Ultra HD, includes latest Alexa Voice Remote"}
            price={59.99}
            rating={3}
            image={"firecube1.jpg"}
          />
          </div>
        
      </div>
    </>
  )
}

export default Home