import React from 'react'
import "./Header.css"
import { Link } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import { ShoppingBasket } from '@mui/icons-material';
import { useStateValue } from '../../StateProvider';


const Header = () => {
    const [{basket},dispatch] = useStateValue();
    console.log("Basket", basket)
    return (
        <nav className='header'>
            <Link to="/">
                <img
                    className='header__logo'
                    src="amazonlogo.png"
                    alt="Amazon Logo"
                />
            </Link>
            <div className="header__search">
                <input type="text" className="header__searchInput" />
                <SearchIcon className='header__searchIcon' />
            </div>

            <div className="header__nav">
                <Link to="/login" className='header__link'>
                    <div className="header__option">
                    <span className='header__optionLineOne'>Hello Dscott </span>
                    <span className='header__optionLineTwo'>Sign In</span>
                    </div>                   
                </Link>
                
                <Link to="/login" className='header__link'>
                    <div className="header__option">
                    <span className='header__optionLineOne'>Return </span>
                    <span className='header__optionLineTwo'>& Orders </span>
                    </div>                   
                </Link>
                <Link to="/login" className='header__link'>
                    <div className="header__option">
                    <span className='header__optionLineOne'>Your </span>
                    <span className='header__optionLineTwo'>Prime </span>
                    </div>                   
                </Link>
                <Link to="/checkout" className='header__link'>
                    <div className="header__optionBasket">
                        <span><ShoppingBasket/></span>
                        <span className='header__optionLineTwo header__optionBasketCount'>{basket?.length}</span>
                    </div>                   
                </Link>        
            </div>
        


        </nav>
    )
}

export default Header