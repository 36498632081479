import React from 'react'
import "./Product.css"
import { useStateValue } from '../../StateProvider';

const Product = ({id,title,price,image,rating}) => {
    const [{},dispatch] = useStateValue();

    const addToBasket = () => {
        dispatch({
            type: 'ADD_TO_BASKET',
            item: {
                id: id,
                title: title,
                image: image,
                price: price,
                rating: rating
            }

        })
    }
  return (
    <div className='product' key={id}>
        <div className="product__info">
        <p className="product__title">{title}</p>
        <p className="product__price">
            <small>$</small>
            <strong>{price}</strong>
        </p>
        <p className="product__rating">
            {
                Array(rating)
                .fill()
                .map((_) => (<p>⭐</p>))
            }
        </p>
        </div>
        <img src={image} alt={title}/>
        <button onClick={addToBasket}>Add to basket</button>
    </div>
  )
}

export default Product