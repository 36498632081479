import './App.css';
import Home from './Components/Home/Home';
import Checkout from './Components/Checkout/Checkout';
import Login from './Components/Login/Login';


import {
  BrowserRouter as Router,
  Route, Routes
} from "react-router-dom";

function App() {
  return (
   
    <div className="app">
       {/* <Header></Header> */}
    <Routes>
      
    
        
      <Route
          path="/checkout"
          element={
            <Checkout/>          
            
          }
        />

<Route
          path="/login"
          element={
            <Login/>          
            
          }
        />

        <Route
          path="/"
          element={
            <Home/>          
            
          }
        >
          
          </Route>






    </Routes>
    </div>
    
   


  );
}

export default App;
