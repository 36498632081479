import React from 'react'
import "./Login.css"

const Login = () => {
  return (
    <div className='login'>
        
        <h1>Login</h1>
        
        </div>
  )
}

export default Login